<template>
    <div class="px-4">
        <v-radio-group :disabled="numInvitationsToSend !== 0" v-model="invitationType">
            <div v-for="mode in invitationTypes" :key="mode.type">
                <v-radio class="mt-3 caption" color="primary" :value="mode.value" :label="mode.type"></v-radio>
                <span :class="invitationType === mode.value ? 'caption secondary--text' : 'caption'">{{ mode.description }}</span>
            </div>
        </v-radio-group>
        <v-form v-if="invitationType" class="mt-3" ref="form" v-model="valid">
            <h4 class="secondary--text">Email addresses</h4>
            <v-textarea
                v-model="emailsInput"
                :rules="rules.nonEmpty"
                required
                @input="updateEmailString"
                rows="3"
                auto-grow
                :disabled="numInvitationsToSend !== 0"
                outlined>
                <template v-slot:label>Enter user emails</template>
            </v-textarea>
        </v-form>

        <div v-if="emailsInput.length" class="mt-3 secondary--text">
            <h3>
                The following users will receive an invitation email to become
                <span v-if="invitationType === 'orgManagers'">managers</span>
                <span v-if="invitationType === 'orgFaculties'">faculty members</span>
                of this organization.
            </h3>
            <div>
                <ul v-for="(email, index) in emails" :key="index" class="mt-3">
                    <li v-if="email.status === emailValidationOutcomes.LOW_RISK_EMAIL || email.status === emailValidationOutcomes.UNKNOWN_RISK_EMAIL">
                        {{ email.email }}
                        <v-chip class="ml-1" x-small color="success">
                            <v-icon small class="mr-1">check_circle</v-icon>
                            <span class="text-uppercase font-weight-bold">validated</span>
                        </v-chip>
                    </li>
                    <li v-else-if="email.status === emailValidationOutcomes.HIGH_RISK_EMAIL">
                        {{ email.email }}
                        <v-chip class="ml-1" x-small color="error">
                            <v-icon small class="mr-1">cancel</v-icon>
                            <span class="text-uppercase font-weight-bold">validation failed</span>
                        </v-chip>
                    </li>
                    <li v-else-if="email.status === emailValidationOutcomes.UNVALIDATED_EMAIL">
                        {{ email.email }}
                        <v-chip class="ml-1" x-small color="secondary">
                            <v-icon small class="mr-1">warning</v-icon>
                            <span class="text-uppercase font-weight-bold">validation error</span>
                        </v-chip>
                    </li>
                    <li v-else-if="validatingEmails">
                        {{ email.email }}
                        <v-progress-circular :size="20" class="ml-1" indeterminate color="primary" />
                    </li>
                    <li v-else>{{ email.email }}</li>
                </ul>
            </div>
        </div>
        <InvitationErrorsDialog
            :showDialog="showInvitationErrorsDialog"
            :errorType="validationErrorType"
            :invalidEmails="invalidEmailsList"
            @proceedWithTheInvitation="inviteUsers($event.value)"
            @validateEmailsAgain="validateEmailsAndInviteUsers($event.value)" />
        <div class="d-flex align-center justify-center flex-column w-100">
            <v-checkbox v-if="userInfo && userInfo.is_sysadmin" v-model="generateInvitationLinksOnly" class="mb-1 mt-5 pa-0" hide-details>
                <template v-slot:label>
                    <div class="d-flex align-center">
                        Generate invitations but don't send emails
                        <v-chip class="ml-1" color="info" label outlined x-small>Sysadmin</v-chip>
                    </div>
                </template>
            </v-checkbox>
            <v-btn
                :loading="numInvitationsToSend > 0 || validatingEmails"
                class="mr-1"
                @click="validateEmailsAndInviteUsers(true)"
                :disabled="!valid || numInvitationsToSend !== 0 || validatingEmails"
                color="primary">
                <v-icon small>add</v-icon>
                Invite
            </v-btn>
            <v-alert v-if="emailsValidationError || backendError" color="error" icon="warning" class="mt-4" text>
                <div class="d-flex flex-column">
                    <span class="font-weight-bold">Email Validation Error</span>
                    User invitation has failed because one or more emails could not be validated, please try again later.
                </div>
            </v-alert>

            <v-alert v-if="foundInvalidEmails" type="warning" icon="warning" class="mt-4" text>
                <div class="d-flex flex-column">
                    <span class="font-weight-bold">Invalid Emails</span>
                    One or more of the provided emails might be invalid.
                </div>
            </v-alert>

            <v-alert v-if="error" color="error" icon="warning" class="mt-4" text>
                <div class="d-flex flex-column">
                    <span class="font-weight-bold">Invitation Failure Error</span>
                    Failed to invite the following users
                    <ul>
                        <li v-for="(email, index) in failedInvitations" :key="index">
                            {{ email }}
                        </li>
                    </ul>
                </div>
            </v-alert>
        </div>
    </div>
</template>

<script>
import { splitEmails } from '@/utils'
import { emailValidation } from '@/mixins/validateEmail'
import { mapState } from 'vuex'
const InvitationErrorsDialog = () => import('@/components/InvitationErrorsDialog')
export default {
    mixins: [emailValidation],
    components: { InvitationErrorsDialog },
    data() {
        return {
            error: false,
            emailsInput: '',
            invitationType: '',
            rules: {
                nonEmpty: [v => (v && v.length > 0) || 'Please provide a value.']
            },
            emailsData: [],
            emailList: [],
            failedInvitations: [],
            inviting: false,
            valid: false,
            generateInvitationLinksOnly: 0,
            numInvitationsToSend: 0,
            invitationTypes: [
                {
                    type: 'Organization Manager',
                    description: 'Organization members can create and delete space, view all instances, and request the addition of new vendor datasets.',
                    value: 'orgManagers'
                },
                {
                    type: 'Faculty Member',
                    description:
                        'Faculty members can create and delete space, and invite colleagues or students to join a space of which the faculty is an admin.',
                    value: 'orgFaculties'
                }
            ]
        }
    },
    computed: {
        ...mapState(['userInfo']),
        emails: function () {
            if (this.validatedEmails.length) {
                return this.validatedEmails
            } else if (this.emailsData.length) {
                return this.emailsData
            }
            return []
        },
        showInvitationErrorsDialog() {
            return this.foundInvalidEmails || this.backendError || this.emailsValidationError
        },
        validationErrorType() {
            if (this.foundInvalidEmails) {
                return this.emailValidationErrorTypes.INVALID_EMAILS_ERROR
            } else if (this.backendError || this.emailsValidationError) {
                return this.emailValidationErrorTypes.EMAIL_VALIDATION_BACKEND_ERROR
            }
            return null
        }
    },
    methods: {
        updateEmailString: function () {
            this.validatedEmails = []
            const emails = splitEmails(this.emailsInput)
            this.emailsData = emails
            this.emailList = emails.map(email => email.email)
        },
        inviteUsers: function (confirmed) {
            if (confirmed) {
                this.error = false
                let apiURL
                if (this.invitationType === 'orgManagers') {
                    apiURL = '/invitations/invite_org_manager/' + this.$route.params.oid
                } else if (this.invitationType === 'orgFaculties') {
                    apiURL = '/invitations/invite_org_faculty/' + this.$route.params.oid
                }
                this.$store.dispatch('showSnackBar', {
                    snackBarText: 'Inviting users...',
                    snackBarTimeout: 5000,
                    snackBarIcon: 'info'
                })
                this.inviting = true
                this.numInvitationsToSend = this.emailList.length
                this.emailList.forEach(email => {
                    const postBody = { requestee_email: email, generate_link_only: this.generateInvitationLinksOnly }
                    this.$axios
                        .post(apiURL, postBody, { timeout: 300000 })
                        .then(response => {})
                        .catch(() => {
                            this.failedInvitations.push(email)
                            this.error = true
                        })
                        .finally(() => {
                            this.numInvitationsToSend -= 1
                            if (!this.error && this.numInvitationsToSend === 0) {
                                this.inviting = false
                                this.$store.dispatch('showSnackBar', {
                                    snackBarText: 'Invitations have been successfully sent',
                                    snackBarTimeout: 5000,
                                    snackBarIcon: 'check_circle'
                                })
                                this.invitationType = ''
                                this.foundInvalidEmails = false
                                this.emailsInput = ''
                                this.$refs.form.resetValidation()
                            }
                        })
                })
            }
        },
        validateEmailsAndInviteUsers: function (confirmed) {
            if (confirmed) {
                this.validateEmails(this.emailList).finally(() => {
                    if (!this.emailsValidationError && !this.backendError && !this.foundInvalidEmails) {
                        this.inviteUsers(true)
                    }
                })
            }
        }
    }
}
</script>
